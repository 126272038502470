
import React from "react"

import TageditorPage from "../../components/negosyonow/controls/tageditor";


const TageditonlyPage = ({location}) => {
	return <TageditorPage
				location={location}
				allowadd={false}
			/>
}


export default TageditonlyPage;
